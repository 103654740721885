import React, { useContext, Fragment } from "react";
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "../assets/css/index.css";
import Nav from "../view/layouts/nav";
import Footer from "../view/layouts/Footer";
import Registration from "../view/pages/authentication/Registration";
import Login from "../view/pages/authentication/Login";
import ForgotPassword from "../view/pages/authentication/ForgotPassword";
import Error404 from "../view/pages/error/Error404";
import { UserContext } from "../utils/providers/UserProvider";
// eslint-disable-next-line
import { UserController } from "../utils/firebase";
import AdminHome from "./Plugins/Dashboard/Home/AdminHome";
import UserHome from "./Plugins/Dashboard/Home/UserHome";
import Profile from "./Plugins/Users/UserProfileCard";
import Experts from "./experts/List";
import Calendar from "./calendar/Calendar";
import Appointments from "./calendar/Appointments";
import ConsultationLength from "./calendar/pages/ConsultationLength";
import SchedulingAppointment from "./calendar/pages/SchedulingAppointment";
import AppointmentDescription from "./calendar/pages/AppointmentDescription";

// eslint-disable-next-line
import helpers from "../utils/helpers";


const Markup = () => {
  const user = useContext(UserContext);
  const Routes = {
    public: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: Login },
      { url: "register", component: Registration },
      { url: "forgot-password", component: ForgotPassword },
    ],
    user: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: UserHome },
    ],
    admin: [
      /* FIRST ENTRY IS START PAGE */
      { url: "", component: AdminHome },
      { url: "experts", component: Experts },
      { url: "profile/:id", component: Profile },
      { url: "sessions", component: Calendar },
      { url: "appointments", component: Appointments },
      { url: "appointments/consultationlength", component: ConsultationLength },
      { url: "appointments/availableslots", component: SchedulingAppointment },
      { url: "appointments/appointmentdescription", component: AppointmentDescription },
    ],
    global: [{ url: "error-404", component: Error404 }],
  };
  const Permission = user.role ? user.role : "public";
  return user.initialized ? (
    <Router basename="/">
      <div id="main-wrapper" className={"show" + (Permission === "public" ? " auth" : "")}>
        <Nav />
        <div className="content-body">
          <div className="container-fluid">
            <Switch>
              {Routes[Permission].map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  ) : (
    <></>
  );
};


export default Markup;
