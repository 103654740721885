export function reducer(state, action) {
    switch (action.type) {
        case "SET_CONSULTATION_LENGTH":
            return {
                ...state,
                consultationLength: action.payload
            }
        case "SET_DATECLICK":
            return {
                ...state,
                dateClick: action.payload
            }
        case "SET_NAME":
            return {
                ...state,
                name: action.payload
            }
        case "SET_EMAIL":
            return {
                ...state,
                email: action.payload
            }
        case "SET_DESCRIPTION":
            return {
                ...state,
                description: action.payload
            }
        case "SCHEDULE_CONSULTATION":
            return {
                ...state,
                name: action.payload.Name,
                email: action.payload.Email,
                description: action.payload.Description,
                consultationLength: action.payload.ConsultationLength
            }
        case "SET_EVENTS":
            return {
                ...state,
                events: [...state.events, action.payload]
            }
        case "SET_HOURSLOTCLICK":
            return {
                ...state,
                hourSlotClick: action.payload
            }
        default:
            return state
    }
}