/* eslint-disable */
import React, { Component,useContext  } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "metismenujs";
import $ from "jquery";
import { UserContext} from "../../../utils/providers/UserProvider";
const path = window.location.pathname;


class MM extends Component {
  
   constructor() {super();}
   componentDidMount() {
      this.$el = this.el;
     this.mm = new MetisMenu(this.$el);

  
   }
   componentWillUnmount() {
     if(typeof this.mm == 'function') {
      this.mm("dispose");
     }
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

const AdminMenu = ({ menu }) => {
  
   const user = useContext(UserContext);

   return user && user.isAdmin ? (
     <>
       <li className={path == "" ? "mm-active" : ""}>
         <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
           <i className="flaticon-381-networking"></i>
           <span className="nav-text">Administration</span>
         </Link>
         <ul aria-expanded="false">
           <li>
             <Link to="/">Dashboard</Link>
           </li>
         </ul>
       </li>
       <li className={path.includes("experts") ? "mm-active" : ""}>
         <Link
           className="has-arrow ai-icon"
           to="/experts"
           aria-expanded="false"
         >
           <i className="flaticon-381-share"></i>
           <span className="nav-text">Experts</span>
         </Link>
         <ul aria-expanded="false">
           <li>
             <Link to="/experts/">Manage</Link>
           </li>
         </ul>
       </li>

       <li className={path.includes("sessions") ? "mm-active" : ""}>
         <Link
           className="has-arrow ai-icon"
           to="/sessions"
           aria-expanded="false"
         >
           <i className="flaticon-381-calendar"></i>
           <span className="nav-text">Sessions</span>
         </Link>
         <ul aria-expanded="false">
           <li>
             <Link to="/sessions/">Appointments</Link>
           </li>
           <li>
             <Link to="/appointments/">Calendly</Link>
           </li>
         </ul>
       </li>
     </>
   ) : (
     ""
   );

}
/*
Member: Notepad, Settings for User
*/
const UserMenu = ({menu}) => {
   const user = useContext(UserContext);

   return user && !user.isAdmin ? (
     <li className={`${menu.includes(path.slice(1)) ? "mm-active" : ""}`}>
       {user.approved ? (
         <>
           <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
             <i className="fa fa-address-card"></i>
             <span className="nav-text">MyDHC</span>
           </Link>
           <ul aria-expanded="true">
             <li>
               <Link to="#onboarding">Profile</Link>
             </li>
           </ul>
         </>
       ) : (
         <>
           <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
             <i className="flaticon-381-notepad"></i>
             <span className="nav-text">Registration</span>
           </Link>
           <ul aria-expanded="true">
             <li>
               <Link to="#onboarding">Pre-screening</Link>
             </li>
           </ul>
         </>
       )}
     </li>
   ) : (
     ""
   );

}
class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");
     var menuLinks = $(".mm-show li a");

     function toggleFunc() {
       
         return aaa.classList.toggle("menu-toggle");
      }

     $(".deznav").on("click", (event) => {

       if (event.target.hasAttribute("href") && !event.target.hasAttribute("class")) {
         btn.click();
       }
     });

     btn.addEventListener("click", toggleFunc);


   }
   render() {
      /// Path
      const path = window.location.pathname;

      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
               <AdminMenu menu={["", "experts"
                           ]}/>
                <UserMenu menu={[
                              ""
                           ]} />
               </MM>
            
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
