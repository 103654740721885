/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";
import $ from "jquery";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage";
import { UserContext } from "../../../../../../../utils/providers/UserProvider";
import { UserController } from "../../../../../../../utils/firebase";



const SelfAssessment = () => {
  const user = useContext(UserContext);
  const [formObj, setFormObj] = useLocalStorage("form_obj", user);

  const Data = {
    options: [
      "Founder",
      "Executive / C-Suite",
      "Manager",
      "College Student",
      "Entrepreneur",
      "High-Achiever",
      "Leader",
      "Team-Builder",
      "Launching a Business",
      "Serial Entrepreneur",
      "Business Owner",
      "Parent",
      "Caretaker",
      "Other",
    ],
  };
  useEffect(() => {
  
    let o = formObj;
    o.page = 2;
    setFormObj(o);
    //save to firebase;
    
    UserController.updateUserProfile(o);

  }, []);


  const ButtonGrid = (data, name, isMultiselect) => {
    return data ? (
      data.map((val, i) => {
        return (
          <button
            type="button"
            key={val.replace(/\W/g, "") + i}
            className={
              name +
              " btn m-2 btn-lg btn-" +
              (formObj[name] && formObj[name].includes(val)
                ? "primary"
                : "muted")
            }
            onClick={(e) => {
              let c = formObj[name] ?? [];

              if (!c.includes(val)) {
                c.push(val);
              } else {
                c.splice(c.indexOf(val), 1); //deleting
              }
            
              let n = { ...formObj, ...{ [name]: c } };
              

              setFormObj(n);
            }}
          >
            {val}
          </button>
        );
      })
    ) : (
      <></>
    );
  };
  return (
    <section className="step-section mb-5">
      <div className="step-title text-center  mb-5">
        <h3 className="mb-3">Which best describes you?</h3>
        <div className="text-muted text-medium">(check all that apply)*</div>
      </div>
      <div className="row mt-4 pt-2 text-center align-center  justify-content-center">
        {ButtonGrid(Data.options, "SelfAssessment", true)}
      </div>
    </section>
  );
};

export default SelfAssessment;
