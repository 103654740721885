import React, { useState, useEffect, useRef, createContext } from "react";
import { auth, UserController } from "../firebase";
import useLocalStorage from "../hooks/useLocalStorage";
export const UserContext = createContext({ user: null });


const UserProvider = props => {
	const [user, setUser] = useState({ initialized: false });

	// eslint-disable-next-line
	const [formObj, setFormObj] = useLocalStorage("form_obj", user);
	const userRef = useRef(user);
	/* eslint-disable */
	const setFormObjRef = useRef(setFormObj);

	useEffect(() => {

		auth.onAuthStateChanged(async (result) => {

			if (result) {
				const { displayName, email, photoURL, createdAt } = result;
				const profile = await UserController.getUser(result.uid); const isAdmin =
					email.includes("@drhaynescollective.com") ||
						email.includes("maiko@maiko.company") ||
						email.includes("@edison.co") ||
						(profile.role && profile.role.toLowerCase() === "admin"
						)
						? true
						: false;

				let d = { displayName, email, photoURL, createdAt };
				d.role = isAdmin ? "admin" : "user";
				d.isAdmin = isAdmin;

				d.firstName = displayName.split(" ")[0];
				d.lastName = displayName.split(" ")[1];
				userRef.current = {
					...userRef.current,
					...d,
				};

				if (profile) {
					userRef.current = { ...userRef.current, ...profile };
				}
			}

			setUser({ ...userRef.current, ...{ initialized: true } });
		});
	}, []);

	useEffect(() => {
		/* eslint-disable */
		setFormObj(user);
	}, [user]);
	return (
		<UserContext.Provider value={user}>{props.children}</UserContext.Provider>
	)
}

export default UserProvider;