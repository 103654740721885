/* eslint-disable */
import _ from "lodash";
import spacetime from "spacetime";

const helpers = {
  htmlEntities: {
    escape: function (str) {
      if (str && typeof str == "string") {
        var div = document.createElement("div");
        div.appendChild(document.createTextNode(str));
        return div.innerHTML;
      }
    },

    unescape: function (str) {
      if (str && typeof str == "string") {
        var txt = document.createElement("textarea");
        txt.innerHTML = str;
        return txt.value;
      }
    },
  },
  /**
   * Deep diff between two object, using lodash
   * @param  {Object} object Object compared
   * @param  {Object} base   Object to compare with
   * @return {Object}        Return a new object who represent the diff
   */
  difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  },
  alphanumeric: (str) => {
    return str.replace(/[^0-9a-zA-Z]/g, "");
  },
  arrayUnique: (array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  },
  timezones: () => {
    let americas = [];
    let tt = (() => {
      let o = spacetime.now().timezones;
      return Object.keys(o).map((val, i) => {
        let z = {
          value: val.toUpperCase(),
          label: val.toUpperCase().split("_").join(" "),
        };
        if (z.value.indexOf("US/") == 0) {
          americas.push(z);
        } else {
          if (
            typeof z !== "undefined" &&
            typeof z.value !== "undefined" &&
            typeof z.label !== "undefined"
          ) {
            return z;
          }
        }
      });
    })();

    let n = americas.concat(tt);

    return americas; //n;
  },
};

export default helpers;
