import React, { Component } from 'react';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './redux/store/index';
import { BrowserRouter } from 'react-router-dom'

class Appointments extends Component {
    render() {
        return (
            <div>
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>

                </Provider>
            </div>
        );
    }
}

export default Appointments;



