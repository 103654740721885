/* eslint-disable */
  import { React, useState, useEffect, useContext } from "react";

import { Link } from "react-router-dom";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"
import { UserContext } from "../../../../../../../utils/providers/UserProvider"; import { UserController } from "../../../../../../../utils/firebase";
const Skills = () => {
		const user = useContext(UserContext);
	const [formObj, setFormObj] =useLocalStorage("form_obj", user);

  useEffect(() => {
    let o = formObj;
    o.page = 4;
    setFormObj(o); 	setTimeout(() => {
      UserController.updateUserProfile(o);
    }, 1000);  }, []);
const updateBasicInfo = (key, value) => {
  setFormObj({ ...formObj, [key]: value } );
};
	return (
    <section className="step-section mb-5">
      <div className="step-title text-center  mb-5">
        <h3 className="mb-3">What are your strongest skills?</h3>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <textarea
              className="form-control"
              rows="4"
              id="comment"
              name="skills"
              value={formObj.skills || ""}
              onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
