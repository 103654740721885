/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";

import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage";
import { UserContext } from "../../../../../../../utils/providers/UserProvider";
import { UserController } from "../../../../../../../utils/firebase";
import Appointments from "../../../../../../calendar/Appointments";

const Complete = () => {

  const user = useContext(UserContext);
  const [formObj, setFormObj] = useLocalStorage("form_obj", user);
  const [needsPrescreen, setNeedsPrescreen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let o = formObj;
    o.page = 8;
    o.approved = false;
    o.is_submited = true;
    setFormObj(o);

    UserController.updateUserProfile(o);

    //Mail Sended
    UserController.sendMailOnQuestionnaire();
    
    setTimeout(() => {
      window.location.href = "https://calendly.com/info-dhc/consultation";
    }, 3000);
  }, []);
  
  const title = "Done. Book Your Discovery Call Now.";
  const content =
    "Please wait while you are redirected.";

  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12 m-0 m-auto">
      <div className="text-center m-0 p-0">
        <h1 className="mb-4">
          <i className="flaticon-381-success-2"></i>
        </h1>
        <h4 className="text-capitalize m-0 m-auto">{title}</h4>
        <div className="w-100 pt-4">
          <p className="medium text-muted m-0 m-auto">{content}</p>
        </div>{" "}
        <button
          role="button"
          href="https://drhaynescollective.com/contact-us/"
          className="btn btn-muted btn-sm px-4 mt-5 isDisabled disabled"
          disabled={true}
          style={{ cursor: "default" }}
        >
          Please Wait.
        </button>
      </div>
    </div>);
}

export default Complete;
