/* eslint-disable */
  import { React, useState, useEffect, useContext } from "react";

import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"

import { UserContext } from "../../../../../../../utils/providers/UserProvider"; import { UserController } from "../../../../../../../utils/firebase";
const Challenges = () => {
	const user = useContext(UserContext);
	const [formObj, setFormObj] =useLocalStorage("form_obj", user);

	useEffect(() => {
		let o = formObj;
		o.page = 3;
		setFormObj(o); 
		UserController.updateUserProfile(o);  
	}, []);

	const updateBasicInfo = (key, value) => {
	setFormObj({ ...formObj, [key]: value });
	};

	return (
		<section className="step-section mb-5">
			<div className="step-title text-center  mb-5">
				<h3 className="mb-3">Describe the greatest challenge you currently face?</h3>
			</div>
			<div className="row">
				
				<div className="col-12">
				<textarea
							className="form-control"
							rows="4"
							id="comment"
							name="challenge"
							value={formObj.challenge || ""}
							onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
						></textarea>
				</div>
			</div>
		</section>
	);
};

export default Challenges;
