import React from "react";
import { button } from "react-router-dom";
import Loader from "../Common/Loader";
import NoData from "../Common/Tables/NoData";

const MemberList = ({ users, isLoading, onProfile, dataFetched }) => {
 
  return (
    <>
      <div className="card member-list">
        <div className={"card-header" + (!dataFetched ? " invisible" : "")}>
          <h4 className="card-title">Accounts</h4>
        </div>
        <div className="card-body">
          {isLoading || !dataFetched ? (
            <Loader style={{ left: 0, top: 0 }} show={true} />
          ) : users && users.length > 0 ? (
            <div className="table-responsive">
              <div id="MemberListwrapper" className="dataTables_wrapper ">
                <table
                  className="table display mb-4 dataTablesCard card-table dataTable "
                  id="MemberList"
                  role="grid"
                  aria-describedby="MemberListinfo"
                >
                  <thead key={"MemberListThead" + users.length}>
                    <tr key={"MemberListTheadTR" + users.length} role="row">
                      <th
                        key={"ml1" + users.length}
                        className="sorting"
                        tabIndex={0}
                        aria-controls="MemberList"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="ID: activate to sort column ascending"
                        style={{ width: "40%" }}
                      >
                        Member
                      </th>

                      <th
                        key={"ml2" + users.length}
                        className="sorting text-center px-0"
                        tabIndex={0}
                        aria-controls="MemberList"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="ID: activate to sort column ascending"
                        style={{ width: "0%" }}
                      >
                        Status
                      </th>
                      <th
                        key={"ml3" + users.length}
                        className="sorting text-center px-0 d-none"
                        tabIndex={0}
                        aria-controls="MemberList"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="ID: activate to sort column ascending"
                        style={{ width: "0%" }}
                      >
                        <i className="text-dark glyph-icon flaticon-381-folder-11"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody key={"mltbody" + users.length}>
                    {users.map((user, i) => (
                      <tr
                        key={"userrow" + i + user.uid}
                        role="row"
                        className="odd"
                      >
                        <td key={"photo" + i + user.uid}>
                          <div className="media">
                            <button
                              onClick={() => {
                                onProfile(user);
                              }}
                              className="d-table align-middle vertical-align-middle btn-link"
                            >
                              <div className="d-table-cell align-middle">
                                <img
                                  className="rounded-circle "
                                  width="35"
                                  src={
                                    (user.PhotoUrl ?? user.photoURL) ??
                                    "https://i.imgur.com/uDytaVG.png"
                                  }
                                  alt={user.DisplayName ?? user.displayName}
                                />
                              </div>
                              <div className="d-table-cell media-body text-nowrap pl-3">
                                <h6 className="d-inline-block text-black font-w600 fs-16 mb-0 text-truncate text-left w-100">
                                  {user.DisplayName ?? user.displayName}
                                </h6>
                                <span className="fs-14 small d-block text-muted text-truncate">
                                  {user.email}
                                </span>
                              </div>
                            </button>
                          </div>
                        </td>

                        <td className="px-0" key={"creation" + i + user.uid}>
                          {
                            <div className="d-flex align-items-center text-center">
                              <div
                                className={
                                  "badge badge-sm badge-rounded m-0 m-auto w-100 " +
                                 (user.approved 
                                    ? "badge-outline-success approved": "badge-outline-muted Pending")}
                             
                                href="#anch"
                              >
                    {user.approved ? "Active" :"Pending"}
                              </div>
                            </div>
                          }
                        </td>
                        <td
                          key={"actions" + i + user.uid}
                          className="text-center px-0 d-none"
                        >
                          <div className="d-flex justify-content-center">
                            <button
                              className="bg-white contact-icon text-dark btn-link"
                              onClick={() => {
                                onProfile(user);
                              }}
                            >
                              <i
                                className="fa fa-pencil text-dark"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <a
                              className="contact-icon text-dark d-none"
                              href={"mailto:" + user.email}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="las la-envelope text-dark"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex align-items-end justify-content-end pr-4">
                  <div className="dataTables_info text-muted m-0 p-0 text-right">
                    <small> {users.length} Total</small>{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoData
              title={false}
              content={
                <div className="w-100 text-center text-muted">
                  <i className="flaticon-381-success-2 text-success mr-2"></i>
                  {"Up to Date"}
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MemberList;