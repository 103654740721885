/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";
import Select from "react-select";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"
import { UserContext } from "../../../../../../../utils/providers/UserProvider";
import { UserController } from "../../../../../../../utils/firebase";
import helpers from "../../../../../../../utils/helpers";
import spacetime from "spacetime";

const ContactPreferences = () => {
	const user = useContext(UserContext);

	const [formObj, setFormObj] = useLocalStorage("form_obj", user);

	const [showSpinner, setShowSpinner] = useState(false);
	const [selectedTimezone, setSelectedTimezone] = useState({
		value: spacetime.now().timezone().name.toUpperCase(),
		label: spacetime.now().timezone().name.toUpperCase().split("_").join(" "),
	});

	/* eslint-disable */
	useEffect(() => {
		updateSelectedTimeZone('timeZone', selectedTimezone);
		let t = { ...formObj, ...{ timeZone: selectedTimezone } };
		t.page = 1;
		setFormObj(t);

		setTimeout(() => {
			return typeof o !== 'undefined' ? UserController.updateUserProfile({ profile: o }) : false;
		}, 1000);
	}, []);

	const updateSelectedTimeZone = (value) => {
		setFormObj({ ...formObj, ...{ timeZone: value } });
	};

	const updateSelectedTimeContact = (value) => {
		setFormObj({ ...formObj, ...{ timeContact: option } });
	};


	const TimetoContactOpts = [
		{ value: "Morning", label: "Morning" },
		{ value: "Afternoon", label: "Afternoon" },
		{ value: "Evening", label: "Evening" },
		{ value: "Other", label: "Other" },
	];
	const TimeZones = helpers.timezones();

	const CustomClearText = () => "clear all";
	const ClearIndicator = (props) => {
		const {
			children = <CustomClearText />,
			getStyles,
			innerProps: { ref, ...restInnerProps },
		} = props;
		return (
			<div
				{...restInnerProps}
				ref={ref}
				style={getStyles("clearIndicator", props)}
			>
				<div style={{ padding: "0px 5px" }}>{children}</div>
			</div>
		);
	};

	const ClearIndicatorStyles = (base, state) => ({
		...base,
		cursor: "pointer"
	});

	return (
		<section className="step-section mb-5">
			<div className="step-title text-center  mb-5">
				<h3 className="mb-3">Contact Preferences</h3>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="form-group m-0">
						<label className="text-label">Preferred Time Zone</label>
						<div className="note text-muted small mb-3 font-italic">
							Type or select nearest major city to choose timezone.
						</div>
						{TimeZones ? (
							<Select
								id="timezone"
								closeMenuOnSelect={false}
								options={TimeZones}
								components={{ ClearIndicator }}
								styles={{ clearIndicator: ClearIndicatorStyles }}
								onChange={(value) => {
									setFormObj({ ...formObj, ...{ timeZone: value } });
								}}
								value={formObj.timeZone || []}
							/>
						) : (
							false
						)}
					</div>
					<div className="form-group m-0 mt-4">
						<label className="text-label">Best Time to Contact</label>
						<Select
							label="Best Time to Contact"
							isMulti
							id="timeContact"
							onChange={(e) => {

								let p =
									formObj.timeContact && formObj.timeContact.length
										? formObj.timeContact
										: [];
								p.push(e);
								setFormObj({ ...formObj, ...{ timeContact: e } });
							}}
							value={formObj.timeContact || []}
							options={TimetoContactOpts}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactPreferences;
