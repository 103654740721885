/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { UserController } from "../../../utils/firebase";
import Loader from "../Common/Loader";
import { UserContext } from "../../../utils/providers/UserProvider";
import { UncontrolledCollapse, Button, Card, CardBody, Col } from "reactstrap";

const UserProfileCard = (props) => {
	const user = useContext(UserContext);
	const [profile, setProfile] = useState(props.profile ?? false);

	const [isLoading, setIsLoading] = useState(!profile ? true : false);
	const [dataFetched, setDataFetched] = useState(profile ? true : false);
	const loadData = async () => {
		let success = true;
		let uid = props.uid ? props.uid : false;
		if (!profile) {
			const u = await UserController.getUser(uid);
			setUser(u);
			if (!u) { success = false }
		}
		if (success) {
			setTimeout(() => {
				setIsLoading(false);
				setDataFetched(true);
			}, 1000);
		}
	}

	// useEffect(() => {
	// 	if (profile !== props.profile) {
	// 		UserController.updateUserProfile(profile)
	// 	}
	// }, [profile])

	useEffect(() => {
		return loadData();
	}, []);

	return isLoading || !dataFetched ? (
		<Loader style={{ left: 0, top: 0 }} show={true} />
	) : (
		<>
			{" "}
			<div className="card overflow-hidden">
				<div className="text-center p-4 overlay-box bg-profile">
					<div className="profile-photo">
						<img
							src={
								profile.PhotoUrl ??
								profile.photoURL ??
								"https://i.imgur.com/uDytaVG.png"
							}
							width={100}
							className="m-auto img-fluid rounded-circle d-block"
							alt=""
						/>
					</div>
					<h3 className="mt-3 mb-1 text-white">
						{profile.DisplayName ?? profile.displayName}
					</h3>
					<p className="text-white mb-0">
						{profile.Plan ?? "Candidate"}{" "}
					</p>
				</div>
				<div className="card-body p-4 p-md-5">
					<ul className="list-group list-group-flush">
						<li className="list-group-item d-flex justify-content-between px-0">
							<span className="mb-0">Phone Number</span>{" "}
							<strong className="text-dark mw-50 text-truncate">
								{profile.phoneNumber}
							</strong>
						</li>
						<li className="list-group-item d-flex justify-content-between px-0">
							<span className="mb-0">E-mail</span>{" "}
							<strong className="text-dark mw-50 text-truncate">
								{profile.email}
							</strong>
						</li>
						<li className="list-group-item d-flex justify-content-between px-0">
							<span className="mb-0">Best time to Contact</span>
							<strong className="text-dark mw-50 text-truncate">
								{profile.timeContact ? profile.timeContact.map((row, i) => (profile.timeContact.length - 1 === i) ? row.label : row.label + ', ') : '-'}
							</strong>
						</li>
						<li className="list-group-item d-flex justify-content-between px-0">
							<span className="mb-0">Time Zone</span>
							<strong className="text-dark mw-50 text-truncate">
								{profile.timeZone.value.split("_").join(" ")}
							</strong>
						</li>
						<li className="list-group-item d-flex justify-content-between px-0">
							<div className="row w-100 mx-0 px-0">
								<Col className="mx-0 px-0 w-50">
									<span className="mb-0">Questionnaire</span>
								</Col>
								<Col className="mx-0 px-0 w-50 text-right">
									<strong className="text-dark mw-50 text-truncate">
										<Button
											color="primary"
											className="btn-lg btn-link text-gold p-0"
											id="toggler"
										>
											<i
												className="text-large text-gold flaticon-381-menu-3
"
											></i>
										</Button>
									</strong>
								</Col>
							</div>
						</li>

						{profile.approved ? (
							<li className="list-group-item d-flex justify-content-between px-0">
								<span className="mb-0">Status</span>
								<strong className="text-dark mw-50 text-truncate">
									Approved
								</strong>
							</li>
						) : null}
					</ul>
					<div className="row w-100 mx-0 px-0">
						<Col>
							<UncontrolledCollapse toggler="#toggler">
								<ul className="list-group list-group-flush">
									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Self Assessment
										</span>
										<span className="text-gold medium text-medium text-right">
											{profile.SelfAssessment.join(", ") ?? (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>
									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Challenges
										</span>
										<span className="text-gold medium text-medium text-right">
											{profile.challenge ?? (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>
									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Skills
										</span>
										<span className="text-gold medium text-medium text-right">
											{profile.skills ?? (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>

									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Goals
										</span>

										<span className="text-gold medium text-medium text-right">
											{profile.goals.length ? profile.goals.join(",") : (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>
									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Interest with Experts
										</span>
										<span className="text-gold medium text-medium text-right">
											{profile.interest_with_experts.join(",") ?? (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>
									<li className="list-group-item d-flex justify-content-between px-0">
										<span className="mb-0 text-muted medium text-medium">
											Coaching Importance
										</span>
										<span className="text-gold medium text-medium text-right">
											{profile.CoachingImportance ?? (
												<i className="flaticon-381-multiply-1 text-light"></i>
											)}
										</span>
									</li>
								</ul>
							</UncontrolledCollapse>
						</Col>
					</div>

					{user.isAdmin ? (
						<div className="card-footer w-100 m-0 p-0 pt-4">
							{!profile.approved ? (
								<>
									<button
										onClick={() => {
											if (confirm("Approve " + profile.email + "?")) {
												UserController.updateUserProfileById(
													{ approved: true },
													profile.uid
												);
												UserController.sendMailOnApprove(profile.uid);
												setProfile({ ...profile, ...{ approved: true } });
											}
										}}
										className="btn btn-lg btn-primary w-100"
									>
										<i className="flaticon-381-success-2 mr-3"></i>Approve
									</button>
								</>
							) : (
								<>
									<button
										onClick={() => {
									
											UserController.sendAgreementMail(profile.uid);
										}}
										className="btn btn-lg btn-secondary w-100 mt-3 mb-3"
									>
										<i className="flaticon-381-credit-card mr-3"></i>Send Agreements
									</button>
									<button
										onClick={() => {
											if (confirm("Deauthorize " + profile.email + "?")) {
												UserController.updateUserProfileById(
													{ approved: false },
													profile.uid
												);

												//Mail Sended
												UserController.sendMailOnDisapprove(profile.uid);
												setProfile({ ...profile, ...{ approved: false } });
											}
										}}
										className="btn btn-lg btn-outline-danger w-100"
									>
										<i className="flaticon-381-danger-2 mr-3"></i>Deauthorize
										Account
									</button>
								</>
							)}
						</div>
					) : null}
				</div>
			</div>
			{user.isAdmin ? (
				<div className="w-100 text-center mt-4 mb-4">
					<button
						onClick={() => {
							props.clearProfile();
						}}
						className="btn btn-sm btn-outline-muted w-auto  m-0 m-auto"
					>
						<i className="flaticon-381-credit-card mr-3"></i>Back to Dashboard
					</button>
				</div>
			) : null}{" "}
		</>
	);
};

export default UserProfileCard;
