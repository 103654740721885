export function setFieldAction(action, ip) {
    return {
        type: action,
        payload: ip
    }
}

export function scheduleEvent(action, name, email, description, consultationLength) {
    return {
        type: action,
        payload: {
            Name: name,
            Email: email,
            Description: description,
            Consultationlength: consultationLength,
        }
    }
}