


const Loader = ({show, style}) => {

    return show ? <div style={style ? style : {}}className="position-absolute h-100 w-100 d-table"><div className="w-100 h-100 text-center d-table-cell align-middle">

    <div className="spinner-grow"  role="status">
  <span className="sr-only">Loading...</span>
</div>  </div></div>: '';
}
export default Loader;