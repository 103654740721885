import React, { useState, useEffect} from "react";
import { storage, ExpertController } from "../../utils/firebase";
import helpers from "../../utils/helpers";
import FileUploader from "react-firebase-file-uploader";
import { Modal, Button} from "react-bootstrap";

const ExpertModal = ({ expert, toggleModal, modalOpen })=>{
 
  const [profile, setProfile] = useState(false);
  const handleChange = (event) => {
    let p = profile;
    p[event.target.name] = helpers.htmlEntities.escape(event.target.value);

    setProfile(p);
   
  }
  useEffect(() => {
    let p = {
      name: expert ? expert.name :false,
      title: expert ? expert.title : "",
      description: expert ? unescape(expert.description) : "",
      image: expert ? expert.image : "",
      status: expert && expert.status ? expert.status : true,
      id: expert ? expert.id :false,
      isUploading: false,
      progress: 0,
    };
    setProfile(p);
  }, [expert]);
  const handleDelete = () => {
    const o = window.confirm("Delete " + profile.name + "?");
    let p = profile;
    p.deleted = true;
   if (o) {
     setProfile(p);
     setTimeout(()=>{handleSaveChanges();},1000)
     
   } 
}
  const handleSaveChanges = async() => {
    let p = profile;
    p.id = !p.id ? helpers.alphanumeric(profile.name) : p.id;
    
    
    await ExpertController.addUpdateExpert(p);
    toggleModal(false);
  }
const handleUploadStart = () => {
    console.log('starting upload')
    setProfile({ ...profile, ...{ isUploading: true, progress: 0 } });
  }
const handleProgress = progress => setProfile({ ...profile, ...{ progress } });
const handleUploadError = error => {
    setProfile({ isUploading: false });
    console.error(error);
  };
const handleUploadSuccess = filename => {

    storage.ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        let p = profile;
        p.image = url;
        p = { ...p, ...{ progress: 100, isUploading: false } };
        setProfile(p);
      });
  }
    return (
      <Modal className="fade" show={modalOpen}>
        <Modal.Header>
          <Modal.Title>
            {profile.name ? profile.name : "Add Expert"}
          </Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => toggleModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {profile ? (
            <form>
              <input type="hidden" autoComplete={"off"} />

              <div className="form-group mb-4">
                <label className="form-label">Name:</label>
                <input
                  autoComplete={"off"}
                  className="form-control"
                  type="text"
                  defaultValue={helpers.htmlEntities.unescape(profile.name)}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label">Title</label>
                <input
                  autoComplete={"off"}
                  className="form-control"
                  type="text"
                  defaultValue={helpers.htmlEntities.unescape(profile.title)}
                  name="title"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label">Description / Bio</label>
                <textarea
                  autoComplete={"off"}
                  className="form-control"
                  type="text"
                  defaultValue={helpers.htmlEntities.unescape(
                    profile.description
                  )}
                  name="description"
                  onChange={handleChange}
                  rows={8}
                />
              </div>
              <div className="row w-100 mx-0">
                <div className="col col-4">
                  {profile.isUploading && <p>Progress: {profile.progress}</p>}
                  {profile.image && (
                    <img
                      className="expert-image"
                      src={profile.image}
                      alt={profile.name}
                    />
                  )}
                </div>
                <div className="col col-8">
                  <label className="-label d-block">Upload Image</label>
                  <FileUploader
                    accept="image/*"
                    name="image"
                    className="form-control"
                    randomizeFilename
                    storageRef={storage.ref("images")}
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={handleProgress}
                  />
                </div>
              </div>
            </form>
          ) : (
            ""
          )}{" "}
        </Modal.Body>
        <Modal.Footer>
          <div className="row w-100">
            <div className="col col-6 text-left p-0">
              {expert.id ? (
                <Button
                  className="btn btn-link text-danger  "
                  onClick={() => {
                    handleDelete();
                  }}
                  variant="primary"
                >
                  Delete Expert
                </Button>
              ) : (
                ""
              )}
            </div>
            <div className="col col-6 text-right p-0">
              <Button
                onClick={() => {
                  handleSaveChanges();
                }}
                variant="primary"
              >
                Save changes
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

export default ExpertModal;
