/* eslint-disable */
import React,{useContext} from "react";

import BasicInfo from "./Content/BasicInfo";
import SelfAssessment from "./Content/SelfAssessment";
import ContactPreferences from "./Content/ContactPreferences";
import Challenges from "./Content/Challenges";
import Skills from "./Content/Skills";
import CoachingImportance from "./Content/CoachingImportance";
import Goals from "./Content/Goals";
import ExpertInterests from "./Content/ExpertInterests";
//import ExpertInterestsDynamic from "./Content/ExpertInterestsDynamic";

import Complete from "./Content/Complete";

const Steps = {
	BasicInfo,
	ContactPreferences,
	SelfAssessment,
	Challenges,
	Skills,
	CoachingImportance,
	Goals, 
	ExpertInterests,
	Complete,
};

export default Steps;
