/* eslint-disable */
import React, { useState } from "react";
import { css, styled, setup } from "goober";
import helpers from "../../../../../utils/helpers";

setup(React.createElement);

const Ol = styled("ol")`
  margin: 0;
  padding-bottom: 2.2rem;
  list-style-type: none;
`;

const LiClass = (props) => css`
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  padding: 0 0.7rem;
  cursor: pointer;

  color: ${props.state === "todo" ? "#d6d7dc" : "#0f1133"};
  border-bottom: 4px solid ${props.state === "todo" ? "#d6d7dc" : "#69cacf"};

  &:before {
    position: relative;
    bottom: -4.03rem;
    float: left;
    left: 50%;

    ${props.state === "todo"
      ? 'content: "\u039F";'
      : props.state === "doing"
      ? 'content: "\u2022";'
      : 'content: "\u2713";'}
    color: ${props.state === "todo"
      ? "#d6d7dc !important"
      : "#69cacf !important"};
    background-color: ${props.state === "todo" ? "#d6d7dc" : "#d6d7dc"};
    background-color: ${props.state === "doing"
      ? "#44aab4 !important"
      : "#69cacf"};

    width: 1.2em;
    line-height: ${props.state === "todo" ? "1.2em" : "1.2em"};
    border-radius: ${props.state === "todo" ? "0" : "1.2em"};
  }
  &:hover,
  &::before {
    color: #69c9d0;
  }
  &:after {
    content: "\\00a0\\00a0";
  }
  span {
    padding: ; /*0 1.5rem;*/
  }
`;
const getTopNavStyles = (indx, length) => {
  let styles = [];
  for (let i = 0; i < length; i++) {
    if (i < indx) {
      styles.push("done");
    } else if (i === indx) {
      styles.push("doing");
    } else {
      styles.push("todo");
    }
  }
  return styles;
};

const getButtonsState = (indx, length) => {
  if (indx > 0 && indx < length - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    };
  } else if (indx === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    };
  } else if (indx === length - 1) {
    return {
      showPreviousBtn: false,
      showNextBtn: false,
    };
  }
};

export default function MultiStep(props) {
  let showNav = true;
  if (props.showNavigation) showNav = props.showNavigation;

  let prevStyle = {};
  if (props.prevStyle) prevStyle = props.prevStyle;

  let nextStyle = {};
  if (props.nextStyle) nextStyle = props.nextStyle;

  const [stylesState, setStyles] = useState(
    getTopNavStyles(0, props.steps.length)
  );
  const [compState, setComp] = useState(props.startAt || 0);
  const [buttonsState, setButtons] = useState(
    getButtonsState(props.startAt || 0, props.steps.length)
  );

  const setStepState = (indx) => {
    setStyles(getTopNavStyles(indx, props.steps.length));

    setComp(indx < props.steps.length ? indx : compState);
    setButtons(getButtonsState(indx, props.steps.length));
  };

  const next = () => setStepState(compState + 1);
  const previous = () =>
    setStepState(compState > 0 ? compState - 1 : compState);
  const handleKeyDown = (evt) =>
    evt.which === 13 ? next(props.steps.length) : {};

  const handleOnClick = (evt) => {
    if (
      evt.currentTarget.value === props.steps.length - 1 &&
      compState === props.steps.length - 1
    ) {
      setStepState(props.steps.length);
    } else {
      setStepState(evt.currentTarget.value);
    }
  };

  const renderSteps = () =>
    props.steps.map((s, i) => (
      <li
        className={LiClass({ state: stylesState[i] })}
        onClick={handleOnClick}
        key={i}
        value={i}
      >
        <span>{i + 1}</span>
      </li>
    ));

  const isValidated = (formId) => {
    let canSubmit = true;
    const l = document.getElementsByClassName("step-section mb-5")[0];

    for (const el of l.querySelectorAll("[required]")) {
      if (!el.reportValidity()) {
        el.setAttribute("invalid", true);
        canSubmit = false;
      }
    }
    return canSubmit;
  };
  const renderNav = (show) =>
    show && (
      <div className="row">
        <div className="col col-6 text-left">
          {buttonsState.showPreviousBtn ? (
            <button
              className="btn btn-muted btn-outline-muted btn-lg bg-white px-5"
              onClick={(e) => {
                e.preventDefault();
                previous();
                return false;
              }}
            >
              Back
            </button>
          ) : (
            ""
          )}
        </div>
        <div className="col col-6 text-right">
          {buttonsState.showNextBtn ? (
            <button
              className="btn btn-secondary btn-lg px-5"
              onClick={(e) => {
                e.preventDefault();
                
                return !!props.enforceRequired && !isValidated()
                  ? false
                  : next();
              }}
            >
              Next
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  return (
    <>
      {props.steps.length - 1 !== compState ? <Ol>{renderSteps()}</Ol> : ""}
      {props.steps[compState].component}
      {renderNav(showNav)}
    </>
  );
}
