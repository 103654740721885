/* eslint-disable */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import AppointmentDescription from './pages/AppointmentDescription';
import SchedulingAppointment from './pages/SchedulingAppointment';
import ConsultationLength from './pages/ConsultationLength'

// gapi config
var gapi = window.gapi
var CLIENT_ID = '725509885516-tbmb2m5b0m06lvqkt0u56a02ds5ooreh.apps.googleusercontent.com'
var API_kEY = 'AIzaSyCOXEPw_BXlj24J9-j9JZrr-6Q_P0l-UI0'
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.readonly"


class App extends Component {


    componentDidMount() {
        gapi.load('client:auth2', () => {
            console.log('Loaded Client')

            gapi.client.init({
                apiKey: API_kEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            })
                .then(() => {
                    gapi.auth2.getAuthInstance().signIn()
                })
        })
    }

    render() {
        return (
            <div>
                <ConsultationLength />
                <SchedulingAppointment />
                <AppointmentDescription />
            </div>

            /*<Switch>
                <Route exact path='/consultation' component={ConsultationLength} />
                <Route path='/freeslot' component={SchedulingAppointment} />
                <Route path='/scheduleevent' component={AppointmentDescription} />  
            </Switch>
            */
        );
    }
}

export default App;