/* eslint-disable */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import helpers from "./helpers";
import experts from "../constants/content/experts.json";
import { cssNumber } from "jquery";
import email_templates from "./email_templates.json";


const firebaseConfig = {
	apiKey: "AIzaSyDFcmlQvlzrO5vSbIcUVQGRse3h-UNBOq8",
	authDomain: "drhaynes-7f6c8.firebaseapp.com",
	projectId: "drhaynes-7f6c8",
	storageBucket: "drhaynes-7f6c8.appspot.com",
	messagingSenderId: "479988475481",
	appId: "1:479988475481:web:2c033f89c486b33009b348"
};
export const firebaseApp = firebase.apps.length === 0 ? firebase.initializeApp(firebaseConfig) : false;


export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();

if (location.hostname === "localhost") {
	firebase.functions().useEmulator("localhost", 5001);
}
const googleProvider = new firebase.auth.GoogleAuthProvider();

const doesUserExist = async (email) => {
	const doc = await firestore.collection("users").where("email", "==", email).get();
	if (doc.exists) {
		return true;
	}
	return false;
};

export const signInWithGoogle = () => {
	auth.signInWithPopup(googleProvider).then(async (res) => {
		const userData = {
			DisplayName: res.user.displayName,
			email: res.user.email,
			role: "user",
			photoURL: res.photoURL ? res.photoURL : "https://i.imgur.com/ff8fLij.png",
			approved: false,
			is_submited: false
		};

		let checkUserExist = await doesUserExist(res.user.email)
		if (!checkUserExist) {
			firestore.collection("users")
				.doc(res.user.uid)
				.set(userData)
				.then(function () {
					console.log("Document successfully written!");
				})
				.catch(function (error) {
					console.error("Error writing document: ", error);
				});
		}
	});
};

export const userDetails = async (uid) => {
	var query = await (await firestore.collection("users").where("uid", "==", uid).get()).docs;
	const user = query[0];
	return user;
};

export const logOut = () => {
	return auth
		.signOut()
		.then(() => {
			window.location.href = "/";
			return true;
		})
		.catch((error) => {
			return false;

		});
};

export const UserController = {
	getAllUsers: async () => {
		const snapshot = await firestore.collection("users").get();
		let s = [];
		snapshot.forEach((doc) => {
			let u = doc.data();
			u.uid = doc.id;
			u.id = doc.id;
			if (u.is_submited) {
				s.push(u);
			}
			
			return doc.data();
		});
		localStorage.setItem("member_total", s.length);

		return s;
	},
	getUser: async (id) => {
		const doc = await firestore.collection("users").doc(id).get();
		if (!doc.exists) {
			return false
		} else {
			return doc.data();
		}
	},
	isLoggedIn: async () => {
		let x = auth.currentUser;
		console.log(
			"Init with user at " +
			(auth.currentUser ? auth.currentUser.email : " NONE")
		);

		return x;
	},
	updateUserProfileById: async (profile,id) => {
		if (functions) {
		  const updateUserProfile = functions.httpsCallable("updateUserProfile");

		  updateUserProfile({ update: profile, uid: id});
		}
	},
	updateUserProfile: async (profile) => {
		if (functions) {
		  const updateUserProfile = functions.httpsCallable("updateUserProfile");

		  updateUserProfile({ update: profile, uid: auth.currentUser.uid }).then(
		    (result) => {
		      const data = result.data;

		    }
		  );
		}
	},
	sendAgreementMail: async (uid) => {
		if (functions) {
			const sendMail = functions.httpsCallable("sendMail");

			const doc = await firestore.collection("users").doc(uid).get();
			
			const user = doc.data();
			if(user) {
				const mailOptions = {
					to: user.email, // Need to send mail to DHC management
					subject: 'DHC Coaching Agreement', // email subject
					html: `https://in1.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhC3NwjFmcE4ycvTv3OCTN5R3Ky9zRnXm3IuP7V877ccwKRGmmvwdajzf7zz1JRe3n0*
							<p>Please do sign on agreement and verify email.</p>
							<br />
							Thank You!
						` // email content in HTML
				};

				sendMail({ mailOptions }).then(
					(result) => {
						const data = result.data;
						console.log(data)
					}
				);
			}
		}
	},sendMailOnQuestionnaire: async () => {
		if (functions) {
			const sendMail = functions.httpsCallable("sendMail");

			const mailOptions = {
				to: 'admin@drhaynescollective.com', // Need to send mail to DHC management
				subject: 'New DHC Candidate', // email subject
				html: `Hello team,
						<p>A new account has been registered. Please review the applicants questionnaire and Approve or Decline the user.</p>
						<br />
						Thank You!
					` // email content in HTML
			};

			sendMail({ mailOptions }).then(
				(result) => {
					const data = result.data;
					console.log(data)
				}
			);
		}
	},
	sendMailOnApprove: async (uid) => {
		if (functions) {
			const sendMail = functions.httpsCallable("sendMail");

			const doc = await firestore.collection("users").doc(uid).get();
			
			const user = doc.data();
			if(user) {
				const mailOptions = {
          to: user.email, // Need to send mail to DHC management
          subject: "Welcome to the DHC Community", // email subject
          html: `Welcome to the DHC Community,
							<p>Thank you for taking the time to fill out the DHC questionnaire.  
								You have been selected to register for a complimentary 30-minute coaching/goal-setting consultation 
								with the Dr. Haynes Collective to ensure we are a mutually good fit to achieve your goals. </p>
							<p>Kindly schedule your complimentary 30-minute coaching session using the following link: https://drhaynescollective.com/consultation/ </p>
							<br />
							We look forward to empowering you to achieve both balance and success!
						`, // email content in HTML
        };
	
				sendMail({ mailOptions }).then(
					(result) => {
						const data = result.data;
						console.log(data)
					}
				);
			}
		}
	},
	sendMailOnDisapprove: async (uid) => {
		if (functions) {
			const sendMail = functions.httpsCallable("sendMail");
			const doc = await firestore.collection("users").doc(uid).get();
			const user = doc.data();
			if(user) {
				const mailOptions = {
          to: user.email, // Need to send mail to DHC management
          subject: "Information Regarding Your Submission", // email subject
          html: "<p>Dear Candidate,<br><br>Thank you for taking the time to complete the DHC questionnaire. Unfortunately, at this time, we are no longer able to accept new members as our schedule is at capacity.  <br><br>DHC takes pride in providing quality time to each member as our goal is to help build communities by empowering groups and individuals and we do not want to lessen our support by offering less value. <br>Upon request, your name will be added to our waiting list and we will be in contact when space is available. <br><br>Again, thank you for your interest in DHC and although we are not able to offer our coaching services at this time, we strongly encourage you to continue reaching for your goals.<br><br>Sincerely,<br>Dr. Haynes Collective</p>", // email content in HTML
        };
	
				sendMail({ mailOptions }).then(
					(result) => {
						const data = result.data;
						console.log(data)
					}
				);
			}
		}
	},
};
export const ExpertController = {
	getAllExperts: async () => {
		const snapshot = await firestore.collection("experts").get();
		let s = [];
		snapshot.forEach((doc) => {
			let u = doc.data();
			u.uid = doc.id;
			u.id = doc.id;
			s.push(u);

			return doc.data();
		});
		return s;
	},
	addUpdateExpert: async ({ name, title, description, image, id, deleted }) => {
		const data = {
			name,
			title,
			description,
			image,
			deleted: deleted ?? false
		};

		const res = await firebase.firestore()
			.collection("experts")
			.doc(id ?? helpers.alphanumeric(name))
			.set(data);
		return res;
	},
	bulkAddExperts: async () => {

		experts.forEach(async (expert) => {
			//await firebase.firestore().collection("experts").doc(helpers.alphanumeric(expert.name)).set(expert);

		});

	}
};

