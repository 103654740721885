/* eslint-disable */
import React, { Fragment, useState, useEffect, useCallback } from "react";
import Markup from "./components";
import "./assets/css/style.css";
import "./assets/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import { auth } from "./utils/firebase";
import UserProvider from "./utils/providers/UserProvider";
import { useResizeDetector } from "react-resize-detector";


const App = () => {
   
       
const setSidebarStyle = () => {
   const width = window.innerWidth;
   const body = document.querySelector("body");
  

    width >= 1200
      ? body.setAttribute("data-sidebar-style", "full")
      : width <= 1199 && width >= 767
      ? body.setAttribute("data-sidebar-style", "mini")
      : body.setAttribute("data-sidebar-style", "overlay");
      
}

useEffect(() => {
setSidebarStyle();
window.addEventListener("resize", setSidebarStyle);
 return () => window.removeEventListener("resize", setSidebarStyle);
}, []);

   return (
      <UserProvider>
       <Fragment>
         <Markup />
      </Fragment>
      </UserProvider>
   );
};

export default App;