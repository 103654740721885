/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { setFieldAction } from "../redux/actions/index";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

class ConsultationLength extends Component {
  render() {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.props.setFieldAction("SET_CONSULTATION_LENGTH", "60");
          }}
        >
          60min
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.props.setFieldAction("SET_CONSULTATION_LENGTH", "30");
          }}
        >
          30min
        </Button>
      </div>
    );
  }
}

export default connect(null, { setFieldAction })(ConsultationLength);
