import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { connect } from 'react-redux'
import { setFieldAction } from '../redux/actions/index'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import moment from 'moment'
import Alert from "sweetalert2";
var gapi = window.gapi
var CLIENT_ID = '725509885516-tbmb2m5b0m06lvqkt0u56a02ds5ooreh.apps.googleusercontent.com'
var API_kEY = 'AIzaSyCOXEPw_BXlj24J9-j9JZrr-6Q_P0l-UI0'
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
var SCOPES = "https://www.googleapis.com/auth/calendar.readonly"

const mapStateToProps = state => {
    return {
        eventsarr: state.events,
        consultationLength: state.consultationLength,
        dateClick: state.dateClick
    }
}

class SchedulingAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availSlots: []
        };
    }

    componentDidMount() {

        const formatEvents = (obj) => {
            return {
                title: obj.summary,
                start: obj.start.dateTime,
                end: obj.end.dateTime,
                id: obj.id
            }
        }
        gapi.load('client:auth2', () => {
            console.log('Loaded Client')

            gapi.client.init({
                apiKey: API_kEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            })
                .then(() => {
                    gapi.client.load('calendar', 'v3', console.log('Calendar Loaded'))

                    gapi.client.calendar.events.list({
                        calendarId: 'primary',
                        showDeleted: false
                    }).then((response) => {
                        response.result.items.forEach((item) => { this.props.setFieldAction("SET_EVENTS", formatEvents(item)) })
                    })
                })
        })



    }

    handleDateClick = (arg) => {


        this.state.availSlots.splice(0, this.state.availSlots.length)
        console.log(arg)

        var startDate = new Date()
        startDate.setDate(arg.date.getDate())
        startDate.setHours(0, 0, 0, 0)

        console.log("Start is:", startDate)
        this.props.setFieldAction("SET_DATECLICK", arg.date)

        var endDate = new Date()
        endDate.setDate(arg.date.getDate() + 1)
        endDate.setHours(0, 0, 0, 0)
        console.log("End is:", endDate)
        gapi.client.load('calendar', 'v3', console.log('Calendar Loaded'))
        gapi.client.calendar.events.list({
            calendarId: 'primary',
            showDeleted: false,
            singleEvents: true,
            timeMin: startDate.toISOString(),
            timeMax: endDate.toISOString(),
            timeZone: "Asia/Karachi",

        }
        ).then((res, err) => {
            var obj = {
                "09": 0,
                "10": 0,
                "11": 0,
                "12": 0,
                "13": 0,
                "14": 0,
                "15": 0,
                "16": 0,
                "17": 0,
            }
            if (err) return console.error("Error is :", err)
            const events = res.result.items
            events.forEach((event) => {
                var str = event.start.dateTime

                switch (str.charAt(11) + str.charAt(12)) {
                    case "09":
                        return obj["09"] = 1
                    case "10":
                        return obj["10"] = 1
                    case "11":
                        return obj["11"] = 1
                    case "12":
                        return obj["12"] = 1
                    case "13":
                        return obj["13"] = 1
                    case "14":
                        return obj["14"] = 1
                    case "15":
                        return obj["15"] = 1
                    case "16":
                        return obj["16"] = 1
                    case "17":
                        return obj["17"] = 1
                    default:
                        return console.log("No Match")
                }

            })

            for (const property in obj) {
                if (obj[property] === 0) {
                    this.setState((oldState) => {
                        const newList = [...oldState.availSlots];
                        newList.push(property);
                        return { availSlots: newList };
                    });
                }

            }

        })
    }
    check = slot => {
        if (slot > 12) {
            return `${slot - 12}pm`
        }
        else if (slot === 12) {
            return `${slot}pm`
        }
        else {
            return `${slot}am`
        }
    }
    eventClick = (eventClick) => {
        Alert.fire({
            title: eventClick.event.title,
            html:
                `<div class="table-responsive">
      <table class="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
                eventClick.event.title +
                `</strong></td>
      </tr>
      <tr >
      <td>Start Time</td>
      <td><strong>
      ` +
                eventClick.event.start +
                `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Remove Event",
            cancelButtonText: "Close",
        }).then((result) => {
            if (result.value) {
                eventClick.event.remove(); // It will remove event from the calendar
                gapi.client.calendar.events.delete({
                    calendarId: 'primary',
                    eventId: eventClick.event.id
                }).then(() => {
                    Alert.fire("Deleted!", "Your Event has been deleted.", "success")
                })
                    ;
            }
        });
    };
    render() {

        return (
            <div className='container-calendar'>
                <div>{`${this.props.consultationLength}min Consultation`}</div>
                <div>{moment(this.props.dateClick).format()}</div>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={this.props.eventsarr}
                    dateClick={this.handleDateClick}
                    eventClick={this.eventClick}
                />
                {this.state.availSlots.map((slot, i) => { return <Button key={`Slot-${i}`} value={slot} variant='contained' color='primary' onClick={(e) => { this.props.setFieldAction("SET_HOURSLOTCLICK", e.currentTarget.value) }}>{this.check(slot)}</Button> })}
                <Button component={Link} to={'/appointments/appointmentdescription'} variant='contained' color='primary'>Confirm</Button>
            </div>
        );
    }
}

export default connect(mapStateToProps, { setFieldAction })(SchedulingAppointment);