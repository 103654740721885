import React from "react";


const NoData = ({title, content, isLoading, card})=> {


return <div><div className={"no-data" + (card ? " card member-list" : "")} >
    
{title ? <div className="card-header"><h4 className="card-title">{title}</h4></div> : null}

{content ? <div className="card-body">{content}</div> : ''}

</div>
</div>

}

export default NoData;