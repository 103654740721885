/* eslint-disable */
import { React, useEffect, useContext } from "react";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"; 
import { UserContext } from "../../../../../../../utils/providers/UserProvider"; 
import { UserController } from "../../../../../../../utils/firebase";

const BasicInfo = () => {
	const user = useContext(UserContext);
	const [formObj, setFormObj] = useLocalStorage("form_obj",user);

	useEffect(() => {
		let o = user;
		
		if (!formObj.page || formObj.page === 1) {
			o.page = 0;
			setFormObj(o);
		} else {
			//they should skip.
		}
	}, []);

	const updateBasicInfo = (key, value) => {
		setFormObj({...{ ...formObj, [key]: value }});
	};

	return (
		<section id="basicinfo" className="step-section mb-5">
			<div className="step-title text-center  mb-5">
				<h3 className="mb-3">Basic Information</h3>
				<div className="text-muted text-medium">Complete the fields below to begin the Questionnaire.</div>
			</div>
			<div className="row">
				<div className="col-md-6 mt-3">
					<div className="form-group m-0">
						<label className="text-label  text-center">First Name<span className="text-danger">*</span></label>
						<input
							type="text"
							name="firstName"
							className="form-control text-center text-md-left"
							placeholder="Cameron"
							value={formObj.firstName || ""}
							onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
							required
						/>
					</div>
				</div>
				<div className="col-md-6 mt-3">
					<div className="form-group m-0">
						<label className="text-label  text-center">Last Name<span className="text-danger">*</span></label>
						<input
							type="text"
							name="lastName"
							className="form-control text-center text-md-left"
							placeholder="Smith"
							value={formObj.lastName || ""}
							onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
							required
						/>
					</div>
				</div>
				<div className="col-md-6 mt-3">
					<div className="form-group m-0">
						<label className="text-label  text-center">Email Address</label>
						<input
							type="email"
							name="email"
							className="form-control text-center text-md-left"
							aria-describedby="inputGroupPrepend2"
							placeholder="example@example.com"
							value={formObj.email || ""}
							onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
							required
							readOnly
						/>
					</div>
				</div>
				<div className="col-md-6 mt-3">
					<div className="form-group m-0">
						<label className="text-label  text-center">Phone Number<span className="text-danger">*</span></label>
						<input
							type="text"
							name="phoneNumber"
							className="form-control text-center text-md-left"
							placeholder="(+1)408-657-9007"
							value={formObj.phoneNumber || ""}
							onChange={(e) => updateBasicInfo(e.target.name, e.target.value)}
							required
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BasicInfo;
