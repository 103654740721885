import React from "react";

import EventCalendar from "./EventCalendar";


const Calendar = () => {
   return (
      <div className="h-80">

         <div className="row">
            <EventCalendar />
         </div>
      </div>
   );
};

export default Calendar;
