/* eslint-disable */
import React, { useEffect, useState, Fragment } from "react";
import { useHistory } from 'react-router-dom';
import Nav from '../../../../view/layouts/nav';
import Footer from '../../../../view/layouts/Footer'
import PageTitle from '../../../../view/layouts/PageTitle';
import DashboardMetrics from "./Admin/DashboardMetrics";
import MemberList from "../../Common/MemberList";
import UserProvider from "../../../../utils/providers/UserProvider";
import { UserController } from "../../../../utils/firebase";
import UserProfileCard from "../../Users/UserProfileCard";

const AdminHome = props => {
	const { user, location } = props;
	const path = location.pathname.split("/");
	const [users, setUsers] = useState([]);
	const [pendingUsers, setPendingUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [dataFetched, setDataFetched] = useState(false);
	const [profile, setProfile] = useState(
		path[0] == "profile" && path[1].length > 6
			? location.data.user
			: false
	);

	const history = useHistory();

	const isAuth = false;

	useEffect(() => {
		return (async () => {
			const u = await UserController.getAllUsers();
			setUsers(u ?? []);
			setTimeout(() => {
				setIsLoading(false);
				setDataFetched(true);
			}, 1000);

			setPendingUsers(u.filter((account) => account.approved==false));

		})();
	}, []);
	return (
		<Fragment>
			<PageTitle activeMenu="Dashboard" motherMenu="Administration" />
			{!profile ? (
				<>
					<DashboardMetrics
						pendingUsers={pendingUsers.length}
						allUsers={users.length}
					/>
					<div className="row">
						<div className="col">
							<MemberList
								users={users}
								onProfile={setProfile}
								isLoading={isLoading}
								dataFetched={dataFetched}
							/>
						</div>
					</div>
				</>
			) : (
				<UserProfileCard
					clearProfile={() => {
						setProfile(false);
					}}
					profile={profile}
				></UserProfileCard>
			)}

		</Fragment>
	);
}

export default AdminHome;