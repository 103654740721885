/* eslint-disable */
import React, { useState } from "react";
import helpers from "../../utils/helpers";
import Loader from "../Plugins/Common/Loader";
import NoData from "../Plugins/Common/Tables/NoData";
import ExpertModal from "./ExpertModal";
const ExpertList = ({ experts, activeExpert, modalOpen, toggleActiveExpert, toggleModal, isLoading, dataFetched }) => {
 
  return (
    <>
      <ExpertModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        expert={activeExpert}
      />
      <div className="card member-list">
        <div className={"card-header" + (!dataFetched ? " invisible" : "")}>
          <h4 className="card-title">Experts</h4>{" "}
          <button onClick={()=>{toggleModal(true);}} className="btn btn-sm btn-primary btn-rounded">
            Add Expert
          </button>
        </div>
        <div className="card-body">
          {isLoading || !dataFetched ? (
            <Loader style={{ left: 0, top: 0 }} show={true} />
          ) : experts && experts.length > 0 ? (
            <div className="table-responsive">
              <div id="ExpertListwrapper" className="dataTables_wrapper ">
                <table
                  className="table display mb-4 dataTablesCard card-table dataTable "
                  id="ExpertList"
                  role="grid"
                  aria-describedby="ExpertListinfo"
                >
                  <thead className="d-none" key={"ExpertListThead" + experts.length}>
                    <tr key={"ExpertListTheadTR" + experts.length} role="row">
                      <th
                        key={"ml1" + experts.length}
                        className="sorting"
                        tabIndex={0}
                        aria-controls="ExpertList"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="ID: activate to sort column ascending"
                        style={{ width: "40%" }}
                      >
                        Name
                      </th>

                 
                   
                    </tr>
                  </thead>
                  <tbody className="border-top-0" key={"mltbody" + experts.length}>
                      {experts.map((expert, i) => (
                        !expert.deleted ? <tr
                          key={"expertrow" + i + expert.uid}
                          role="row"
                         
                     
                       >
                          <td key={"photo" + i + expert.uid}    className={(i == 1 ? "border-top-0 " : "") + "expert-cell p-4 pointer"}
                        >
                            <div
                              onClick={() => {
                            
                                toggleModal(true);
                                toggleActiveExpert(expert);
                              }}
                              className="media"
                            >
                              <div className="d-table-cell align-middle">
                                <div className="rounded-circle overflow-none w-auto">
                                  <img
                                    width="35"
                                    src={
                                      expert.image ??
                                      "https://i.imgur.com/uDytaVG.png"
                                    }
                                    alt={expert.name}
                                  />
                                </div>
                              </div>
                              <div className="d-table-cell media-body text-nowrap pl-3">
                                <h6 className="d-inline-block text-black font-w600 fs-16 mb-0 text-truncate">
                                  {expert.name}
                                </h6>
                                <span className="fs-14 small d-block text-muted text-truncate">
                                  {helpers.htmlEntities.unescape(expert.title)}
                                </span>
                              </div>
                            </div>
                          </td>

                         </tr> : null
                    ))}
                  </tbody>
                </table>

                <div className="d-flex align-items-end justify-content-end pr-4">
                  <div className="dataTables_info text-muted m-0 p-0 text-right">
                    <small> {experts.length} Total</small>{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <NoData
              title={false}
              content={
                <div className="w-100 text-center text-muted">
                  <i className="flaticon-381-success-2 text-success mr-2"></i>
                  {"Up to Date"}
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ExpertList;
