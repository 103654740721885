

const Globals = {
    showHeaderSearch : false,
showGlobalNotifications : false,
showMessageNotifications: false, 
 
  }

export default Globals;
