/* eslint-disable */
import React, { Fragment, useContext } from "react";
import Multistep from "../../../Common/Forms/react-multistep/react-multistep";
import Steps from "./Steps/index";
import { UserContext } from "../../../../../utils/providers/UserProvider";

import useLocalStorage from "../../../../../utils/hooks/useLocalStorage";

const PreScreen = () => {
	const user = useContext(UserContext);
	const [formObj, setFormObj] = useLocalStorage("form_obj", user);
	
	const Questionnaire = Object.keys(Steps).map((key) => {
		const DynamicComp = Steps[key];
		return { name: key, component: <DynamicComp /> };
	});
		
	return (
		<Fragment>
			<div className="row justify-content-center">
				<div className="col-12 col-xl-12 col-xxl-12">
					<div className="card">
						
						<div className="card-body p-5">
							<form
								action="#"
								onSubmit={()=>{return false}}
							
								className="step-form-horizontal"
							>
								<Multistep
									showNavigation={true}
									steps={Questionnaire}
									startAt={formObj.page ? formObj.page : 0}
									enforceRequired
								/>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PreScreen;
