import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { setFieldAction } from '../redux/actions/index'

class InputForm extends Component {
    handleNameChange = e => {
        const ip = e.target.value
        this.props.setFieldAction("SET_NAME", ip)
    }
    handleEmailChange = e => {
        const ip = e.target.value
        this.props.setFieldAction("SET_EMAIL", ip)
    }
    handleDescriptionChange = e => {
        const ip = e.target.value
        this.props.setFieldAction("SET_DESCRIPTION", ip)
    }

    render() {
        return (
            <div>
                <form>
                    <TextField id="outlined-basic" label="Name*" variant="outlined" data-action="SET_NAME" onChange={this.handleNameChange} />
                    <TextField id="outlined-basic" label="Email" variant="outlined" data-action="SET_EMAIL" onChange={this.handleEmailChange} />
                    <TextField
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={4}
                        columns={8}
                        variant="outlined"
                        placeholder="Please share anything that will help prepare for our meeting."
                        data-action="SET_DESCRIPTION"
                        onChange={this.handleDescriptionChange}
                    />
                </form>
            </div>
        );
    }
}

export default connect(null, { setFieldAction })(InputForm);