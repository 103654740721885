import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import InputForm from '../components/InputForm';
import { connect } from 'react-redux';
import { scheduleEvent } from '../redux/actions';
import moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

var gapi = window.gapi

const mapStateToProps = state => {
    return {
        name: state.name,
        email: state.email,
        description: state.description,
        consultationLength: state.consultationLength,
        dateClick: state.dateClick,
        hourSlotClick: state.hourSlotClick
    }
}

class AppointmentDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    onSubmit = () => {
        var isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();

        if (isSignedIn) {
            console.log("Signed In")
            gapi.client.load('calendar', 'v3', console.log('Calendar Loaded'))

            const eventStartTime = new Date()
            eventStartTime.setDate(this.props.dateClick.getDate())
            eventStartTime.setHours(this.props.hourSlotClick, 0, 0, 0)

            const eventEndTime = new Date()
            eventEndTime.setDate(this.props.dateClick.getDate())
            eventEndTime.setHours(this.props.hourSlotClick, this.props.consultationLength, 0, 0)

            console.log(eventStartTime, eventEndTime)
            const event = {
                summary: this.props.name,
                description: this.props.description,
                start: {
                    dateTime: eventStartTime
                },
                end: {
                    dateTime: eventEndTime,
                },
                colorId: 1
            }
            var request = gapi.client.calendar.events.insert({ calendarId: 'primary', resource: event })
            request.execute(function (event) {
                console.log('Event created: ' + event.htmlLink);
            })

            this.setState({
                open: true
            })

        }
        else console.log("Not SignedIn")




    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            open: false
        })
    };
    render() {
        return (
            <div>
                <div>{`${this.props.consultationLength}min Consultation`}</div>
                <div>{moment(this.props.dateClick).format()}</div>
                <div>{`Time : ${this.props.hourSlotClick}`}</div>
                <InputForm />
                <Button variant='contained' color='primary' onClick={this.onSubmit}>Schedule Event</Button>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <MuiAlert onClose={this.handleClose} severity="success">
                        Event Created
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

export default connect(mapStateToProps, { scheduleEvent })(AppointmentDescription);