/* eslint-disable */
import React, { Fragment, useEffect, useContext } from "react";
import PreScreen from "../../Authentication/Registration/PreScreen";
import { useHistory } from 'react-router-dom';
import Nav from '../../../../view/layouts/nav';
import Footer from '../../../../view/layouts/Footer'
import PageTitle from "../../../../view/layouts/PageTitle";
import { UserContext } from "../../../../utils/providers/UserProvider";
import {UserController} from "../../../../utils/firebase";
import UserProfileCard from "../../Users/UserProfileCard";
import Loader from "../../Common/Loader";

const UserHome = props => {
  
   const user = useContext(UserContext);

   const history = useHistory();
   if (!UserController.isLoggedIn) { history.push("/login") }
  
   const isAuth = false;

   return (
   <>
         <PageTitle activeMenu="MyDHC" motherMenu="Home" />
                  {user && user.initialized ? (
                     <div>
                        {user && user.approved ? <UserProfileCard profile={user} /> : <PreScreen user={user} />}
                     </div>
                  ) : <Loader style={{ left: 0, top: 0 }} show={true} />}
         
              </>);
};

export default UserHome;
