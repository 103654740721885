/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";
import { useToastContainer } from "react-toastify";

import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"
import { UserContext } from "../../../../../../../utils/providers/UserProvider"; import { UserController } from "../../../../../../../utils/firebase";
const Goals = () => {
	const user = useContext(UserContext);
	const [formObj, setFormObj] = useLocalStorage("form_obj", user);
	const [goals, setGoals] = useState([]);

	useEffect(() => {
		let o = formObj;
		o.page = 6;
		setGoals(o.goals);
		setTimeout(() => {
			if (typeof o !== 'undefined') { UserController.updateUserProfile(o); }
		}, 1000);
	}, [goals]);
	
	const updateBasicInfo = (key, value) => {
		let f = formObj;
		let g = formObj.goals || [];
		g[parseInt(key)] = value;
		f.goals = g;
		setFormObj(f);
	};
	const handleChange = (e) => {
		updateBasicInfo(parseInt(e.target.name), e.target.value);
	}
	return (
		<section className="step-section mb-5">
			<div className="step-title text-center  mb-5">
				<h3 className="mb-3">
					What are the top 3 goals/areas you'd like to address?
				</h3>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="form-group text-center">
						<label className="d-none">Goal 1</label>
						<input
							type="text"
							className="form-control input-rounded"
							placeholder="Goal 1"
							name="0"
							defaultValue={formObj ? (formObj.goals ? formObj.goals[0] : "") : ""}
							key={"goal0"}
							onChange={handleChange}
						></input>
					</div>
					<div className="form-group text-center">
						<label className="d-none">Goal 2</label>
						<input
							key={"goal1"}
							type="text"
							className="form-control input-rounded"
							placeholder="Goal 2"
							name="1"
							defaultValue={formObj ? (formObj.goals ? formObj.goals[1] : "") : ""}
							onChange={handleChange}
						></input>
					</div>
					<div className="form-group text-center">
						<label className="d-none">Goal 3</label>
						<input
							key={"goal3"}
							type="text"
							className="form-control input-rounded"
							placeholder="Goal 3"
							name="2"
							defaultValue={formObj ? (formObj.goals ? formObj.goals[2] : "") : ""}
							onChange={handleChange}
						></input>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Goals;
