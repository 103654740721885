/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/images/logos/logo.svg";
import { signInWithGoogle } from "../../../utils/firebase";
import { useHistory } from 'react-router-dom';
import Nav from '../../../view/layouts/nav';
import Footer from '../../../view/layouts/Footer'

const Registration = (props) => {
   const { user } = props;
   const [isAuth, setisAuth] = useState(true);
   const history = useHistory();

   useEffect(() => {
      if (user) history.push('/');
   }, [user]);

   return (
      <div id="main-wrapper" className={"show " + (true ? ' auth' : '')} >
      <Nav isAuth={true}/>
         {!isAuth ? 
            <div className="content-body">
            <div className="container-fluid">
               <div className="row justify-content-center h-100 align-items-center h-80">
                  <div className="col-md-6">
                     <div className="authentication-content">
                        <div className="row no-gutters">
                           <div className="col-xl-12">
                              <div className="auth-form bg-white">
                                 <div className="text-center mb-5">
                                    <Link to="/">
                                       <img src={img} className="auth-logo" alt="" />
                                    </Link>
                                 </div>

                                 <div className="text-center mb-5">
                                    <button className="btn btn-dark btn-block" onClick={signInWithGoogle}>
                                       <span> Sign In with Google</span>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               </div>
            </div>
        : ''} 
      </div>
   );
};

export default Registration;
