/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";

import $ from "jquery";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"
import { UserContext } from "../../../../../../../utils/providers/UserProvider"; 
import { UserController } from "../../../../../../../utils/firebase";

const CoachingImportance = () => {
	const user = useContext(UserContext);
	const [formObj, setFormObj] = useLocalStorage("form_obj", user);

	useEffect(() => {
		let o = formObj;
		o.page = 5;
		setFormObj(o);
		setTimeout(() => {
			UserController.updateUserProfile(o);
		}, 1000);
	}, []);

	const updateBasicInfo = (key, value) => {
		setFormObj({ ...formObj, [key]: value });
	};

	const Data = {
		options: ["1", "2", "3", "4", "5"],
	};
	const ButtonGrid = (data, name, isMultiselect) => {

		return data ? (
			data.map((val, i) => {
				let rateNote = (() => {
					if (i == 0) { return 'Low' } else if (i == 4) {
						return 'High'
					} else { return false }
				})();
				return (
					<div key={i + 'bg'} className={"col col-2 px-0 mx-0 text-center" + (i < (data.length - 1) ? " mr-2" : "")}> <button
						type="button"
						key={val.replace(/\W/g, "") + i}
						className={
							name +
							" btn btn-sm btn-" +
							(formObj[name] && formObj[name] == val
								? "primary"
								: "muted")
						}
						value={val}
						name={name}
						onClick={(e) => {

							updateBasicInfo(e.target.name, e.target.value);
						}}
					>
						{val}
					</button>{rateNote ? <div className="rate-note text-muted">{rateNote}</div> : ""}</div>
				);
			})
		) : (
			<></>
		);
	};
	return (
		<section className="step-section mb-5">
			<div className="step-title text-center  mb-5">
				<h3 className="mb-3">
					How important is it to work with a coach/mentor?
				</h3>
			</div>
			<div className="row mt-4 pt-2 px-0 mx-0 text-center align-center  justify-content-center">
				{ButtonGrid(Data.options, "CoachingImportance", true)}


			</div>
		</section>
	);
};

export default CoachingImportance;
