import { createStore } from 'redux'
import { reducer } from '../reducer/index'

const initialState = {
    consultationLength: '',
    name: '',
    email: '',
    description: '',
    dateClick: [],
    hourSlotClick: '',
    events: []
}

export const store = createStore(reducer, initialState);