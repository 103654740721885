/* eslint-disable */
import { React, useState, useEffect, useContext } from "react";

import $ from "jquery";
import useLocalStorage from "../../../../../../../utils/hooks/useLocalStorage"
import { UserContext } from "../../../../../../../utils/providers/UserProvider";
import { UserController } from "../../../../../../../utils/firebase";

const ExpertInterests = () => {
		const user = useContext(UserContext);
		const [formObj, setFormObj] = useLocalStorage("form_obj", user);
	useEffect(() => {
		let o = formObj;
		o.page = 7;
		setFormObj(o);
		setTimeout(
			() => { UserController.updateUserProfile(o); }, 1000);
}, []);

	const data = {
    expertsOptions: [
      {
        name: "Gina Ford",
        subTitle: "Global Commercial Agent for Team Usain Bolt",
        img: "3.jpg",
      },
      {
        name: "Kirk Williams",
        subTitle: "Chief Legal Officer & General Counsel",
        img: "4.jpg",
      },
      {
        name: "Shervin Pishevar",
        subTitle: "Investor & Entrepreneur",
        img: "5.jpg",
      },
      {
        name: "Bita Milanian",
        subTitle: "SVP, Global Marketing",
        img: "6.jpg",
      },
    ],
    options: [
      {
        name: "area",
        list: [
          "I want to work with Dr. Haynes.",
          "I want to work with Dr. Haynes and the Experts.",
          "I want to work with the experts.",
        ],
      },
      {
        name: "pillars",
        list: [
          "Career / Financial",
          "Leadership / Personal Growth",
          "Health & Wellness",
          "Family / Intimacy",
          "Community / Network",
        ],
      },
      {
        name: "subcategories",
        list: [
          "Business Strategy",
          "Marketing",
          "Negotiation",
          "Legal",
          "Wellness",
          "Psychology",

          "Other",
        ],
      },
    ],
  };

	const intrest = {
    list: [
      "Business Strategy",
      "Marketing",
      "Negotiation",
      "Legal",
      "Wellness",
      "Psychology",
      "Other",
      "I'll Decide Later",
    ],
  };

  const ButtonGrid = (data, name, isMultiselect) => {
    return data ? (
      data.map((val, i) => {
        return (
          <button
            type="button"
            key={val.replace(/\W/g, "") + i}
            className={
              name +
              " btn m-2 btn-lg btn-" +
              (formObj[name] && formObj[name].includes(val)
                ? "primary"
                : "muted")
            }
            onClick={(e) => {
              let c = formObj[name] ?? [];

              if (!c.includes(val)) {
                c.push(val);
              } else {
                c.splice(c.indexOf(val), 1); //deleting
              }

              let n = { ...formObj, ...{ [name]: c } };
              

              setFormObj(n);
            }}
          >
            {val}
          </button>
        );
      })
    ) : (
      <></>
    );
  };
	return (
    <section className="step-section mb-5">
      <div className="step-title text-center  mb-5">
        <h3 className="mb-3">Areas of interest with our experts?</h3>
      </div>
      <div className="mt-4 pt-2 text-center align-center justify-content-center">
        {ButtonGrid(intrest.list, "interest_with_experts", true)}
      </div>
    </section>
  );
};

export default ExpertInterests;
