/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ExpertController } from "../../utils/firebase";
import { useHistory } from "react-router-dom";
import Nav from "../../view/layouts/nav";
import Footer from "../../view/layouts/Footer";
import PageTitle from "../../view/layouts/PageTitle";
import ExpertList from "./ExpertList";


const Experts = (props) => {
 
  const { user } = props;
  const [experts, setExperts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [activeExpert, setActiveExpert] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const editExpert = (data) => {
    //Show Modal for editing the expert
    setActiveExpert(data);
    setModalOpen(true);
  }
  const toggleModal = () => {
    if (modalOpen) {
      
      setActiveExpert(false);
    
      //update experts,
      updateExpertList();
    }
    setModalOpen(!modalOpen);
  }
  const updateExpertList = async () => {
     setIsLoading(true);
     setDataFetched(false);
  const u = await ExpertController.getAllExperts();
   setExperts(u);
      setTimeout(() => {
        setIsLoading(false);
        setDataFetched(true);
      }, 1000);

    }

  const history = useHistory();
  const isAuth = false;
  useEffect(() => {
    return (async () => {
      const u = await ExpertController.getAllExperts();
   setExperts(u);
      setTimeout(() => {
        setIsLoading(false);
        setDataFetched(true);
      }, 1000);

    })();
  }, []);

  return (
    <>
   <PageTitle activeMenu="Manage" motherMenu="Experts" /> 
      <div className="row">
        <div className="col">
          <ExpertList
            experts={experts}
            isLoading={isLoading}
            dataFetched={dataFetched}
            activeExpert={activeExpert}
            modalOpen={modalOpen}
            toggleModal={toggleModal}
            toggleActiveExpert={setActiveExpert}
          />
        </div>
      </div>
    </>
  );
};

export default Experts;
